import { useState, useEffect } from 'react'
import Bowser from 'bowser'
import { Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import nativeIcon from './native-icon.png'
import braveIcon from './brave-icon.png'
import chromeIcon from './chrome-icon.png'
import edgeIcon from './edge-icon.png'
import firefoxIcon from './firefox-icon.png'
import operaIcon from './opera-icon.png'
import safariIcon from './safari-icon.png'
import samsungInternetIcon from './samsung-internet-icon.png'
import ucIcon from './uc-icon.png'
import vivaldiIcon from './vivaldi-icon.png'
import yandexIcon from './yandex-icon.png'

const browserIcons = {
    Brave: braveIcon,
    Chrome: chromeIcon,
    Edge: edgeIcon,
    Firefox: firefoxIcon,
    Opera: operaIcon,
    Safari: safariIcon,
    "Samsung Internet": samsungInternetIcon,
    UC: ucIcon,
    Vivaldi: vivaldiIcon,
    Yandex: yandexIcon
}

const NativePrompt = () => {
    const [visible, setVisible] = useState(false)
    const [browser, setBrowser] = useState(null)
    useEffect(() => {
        const doNotShow = localStorage.getItem('disable_native_prompt')
        if (!doNotShow) {
            const browser = Bowser.getParser(window.navigator.userAgent)
            const platform = browser.getPlatformType()
            if (platform === 'mobile') {
                setBrowser(browser.getBrowserName())
                setVisible(true)
            }
        }
    }, [])
    const handleClose = () => {
        setVisible(false)
    }
    return (
        <Modal isOpen={visible} centered>
            <ModalHeader>View this in:</ModalHeader>
            <ModalBody className='p-0'>
                <ListGroup flush>
                    <ListGroupItem className='d-flex align-items-center'>
                        <img src={nativeIcon} alt='App Icon' className='rounded' style={{ width: '3rem' }} />
                        <div className='flex-grow-1 ms-2'>App</div>
                        <Button color='primary' as='a' href={'inspireconnect:/' + window.location.pathname} type='button'>Open App</Button>
                    </ListGroupItem>
                    <ListGroupItem className='d-flex align-items-center'>
                        {!!browserIcons[browser] && <img src={browserIcons[browser]} alt='Web Icon' style={{ width: '3rem' }} />}
                        <div className='flex-grow-1 ms-2'>{browser || 'Web'}</div>
                        <Button color='light' type='button' onClick={handleClose}>Continue</Button>
                    </ListGroupItem>
                </ListGroup>
            </ModalBody>
            <ModalFooter />
        </Modal>
    )
}

export default NativePrompt