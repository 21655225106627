import { Suspense, lazy } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppLoading from 'components/app-loading'
import NativePrompt from 'components/native-prompt'
import 'lib/analytics'
import 'lib/i18n'

import 'assets/fonts/Gotham-Book.otf'
import 'assets/fonts/Gotham-Medium.otf'
import 'assets/fonts/Gotham-Bold.otf'
import 'assets/fonts/Gotham-Ultra.otf'

const Connect = lazy(() => import('./connect'))
const AuthWall = lazy(() => import('components/auth-wall'))
const ScrollToTop = lazy(() => import('components/scroll-to-top'))

const App = () => {

  // useEffect(() => {
  //   const storageKey = 'native_app_suggested_at'
  //   const askInterval = 1000 * 60 * 60 * 24
  //   const suggestedTime = localStorage.getItem(storageKey)
  //   if (!suggestedTime || Number(suggestedTime) < (Date.now() - askInterval)) {
  //     localStorage.setItem(storageKey, Date.now())
  //     const url = 'inspireconnect:/' + window.location.pathname
  //     window.location.href = url
  //   }
  // }, [])

  return (
    <Suspense fallback={<AppLoading />}>
      <Router basename={'/'}>
        <AuthWall>
          <Connect />
        </AuthWall>
        <ScrollToTop />
        <NativePrompt />
      </Router>
    </Suspense>
  )

}

export default App