import Analytics from 'analytics'
import doNotTrack from 'analytics-plugin-do-not-track'
import googleAnalytics from '@analytics/google-analytics'

// const analyticsHttp = (config) => {

//     const { url } = config;

//     if (!url) {
//       throw new Error('URL is required for the analyticsHttp plugin.')
//     }

//     const sendAnalyticsData = (data) => {
//       fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//       }).catch(error => {
//         console.error(error)
//       })
//     }
  
//     return {
//         name: 'analytics-http-plugin',
//         config: {
//             url,
//         },
//         // initialize: ({ config }) => {
//         // },
//         page: ({ payload }) => {
//             sendAnalyticsData({
//                 event: 'page',
//                 data: payload,
//             })
//         },
//         track: ({ payload }) => {
//             sendAnalyticsData({
//                 event: 'track',
//                 data: payload,
//             })
//         },
//         identify: ({ payload }) => {
//             sendAnalyticsData({
//                 event: 'identify',
//                 data: payload,
//             })
//         },
//         loaded: () => {
//             return true
//         }
//     }

// }

const plugins = [
    doNotTrack(),
    // analyticsHttp({
    //     url: `${process.env.REACT_APP_API_URL}/metrics/v1`
    // })
]

if (process.env.NODE_ENV === 'production') {
    plugins.push(googleAnalytics({
        measurementIds: ['G-HXSZSKY2SM']
    }))
}

const analytics = Analytics({
    app: 'inspire-connect',
    plugins
})

export const identify = (userId, traits, options) => analytics.identify(userId, traits, {
    ...options,
    googleAnalytics: false
})
export const page = analytics.page
export const track = analytics.track

// const identify = () => {}
// const page = () => {}
// const track = () => {}

export default { identify, page, track }